import store from '@/store'

// oss
export const getOssUrl = async url => {
  // console.log(1);
  if (!url || url.length === 0) return
  const newTime = Date.now()
  if (store.state.global.OssExpiration - newTime <= 0) {
    // console.log(2);

    await store.dispatch('global/getOssStore')
  }
  // console.log(3);
  if (url.indexOf('https://') !== -1) {
    url = url.replace('https://', '')
  }
  // console.log(3);
  if (url.indexOf('http://') !== -1) {
    url = url.replace('http://', '')
  }
  const index = url.indexOf('/')
  // let aa = store.getters.ossStore.signatureUrl(url.slice(index+1))
  // console.log(4);

  return store.getters.ossStore.signatureUrl(url.slice(index + 1))
}
