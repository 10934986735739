<template>
  <div class="salesman-info">
    <div class="header">
      <div class="box">
        <div class="complain" @click="toggleComplain">投诉</div>
        <div class="head">
          <div class="avatar"><img :src="infos.headImgUrl" alt=""></div>
          <div class="des">
            <div class="name">{{ infos.realName }}</div>
            <div class="age">
              <div class="text">
                <img v-if="infos.sex == 0" :src="girlImg" alt="">
                <img v-else :src="boyImg" alt="">
                <span>{{ infos.age }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cont">
          <div class="item">
            <img :src="icon1" alt="">
            <div class="tt">从业经验</div>
            <div class="ct">{{ infos.workExp }}年</div>
          </div>
          <div class="item">
            <img :src="icon2" alt="">
            <div class="tt">已成交订单</div>
            <div class="ct">{{ infos.orderCount }}单</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="head">
        <img :src="icon3" alt="">
        评价 ({{ list.length }})
      </div>
      <div v-if="list.length > 0 || firstLoading" class="list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getCommentList"
          >
            <div v-for="item in list" :key="item.id" class="item">
              <div class="hd">
                <img :src="item.headImgUrl" alt="">
                <div class="ct">
                  <div class="tt">{{ item.userName }}</div>
                  <div class="dt">{{ item.date | parseTime }}</div>
                </div>
              </div>
              <div class="des">
                <div class="it">
                  <span>专业程度</span>
                  <van-rate v-model="item.majorStar" readonly size="16px" color="#ff7700" void-color="#FF7700" />
                  <span>{{ stars[item.majorStar] }}</span>
                </div>
                <div class="it">
                  <span>服务态度</span>
                  <van-rate v-model="item.serviceStar" readonly size="16px" color="#ff7700" void-color="#FF7700" />
                  <span>{{ stars[item.serviceStar] }}</span>
                </div>
              </div>
              <div class="textarea">{{ item.content }}</div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div v-else class="emprty">
        <img :src="emprty" alt="">
      </div>
    </div>
    <van-popup v-model="show" round>
      <div class="complain-popup">
        <div class="head">
          投诉原因
        </div>
        <div class="form">
          <van-field v-model="form.complaintReason" type="textarea" placeholder="请输入投诉原因..." maxlength="100" show-word-limit />
        </div>
        <div class="bottom">
          <div class="btn" style="border-right:1px solid #E6E6E6" @click="toggleComplain">取消</div>
          <div class="btn" style="color: #FF7700;" @click="goComplainSubmit">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import boyImg from '@/assets/male@2x.png'
import girlImg from '@/assets/female@2x.png'
import icon1 from '@/assets/salesman/experience@2x.png'
import icon2 from '@/assets/salesman/orders@2x.png'
import icon3 from '@/assets/salesman/evaluate@2x.png'
import emprty from '@/assets/salesman/empty1@2x.png'
import { getUserInfo, getCommentList, addComplaint } from '@/api/user'
import { parseTime } from '@/utils/index'
import { getOssUrl } from '@/utils/oss'
export default {
  filters: { parseTime },
  data() {
    return {
      infos: {},
      saleId: '',
      params: { businessId: '', limit: 20, page: 1, createTimeSort: 2 },
      form: { businessId: '', complaintReason: '' },
      count: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: true,
      show: false,
      stars: ['', '非常差', '差', '一般', '好', '非常好'],
      emprty,
      boyImg,
      girlImg,
      icon1,
      icon2,
      icon3
    }
  },
  mounted() {
    this.saleId = this.$route.params.saleId
    this.params.businessId = this.saleId
    this.form.businessId = this.saleId
    this.getInfo()
  },
  methods: {
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.params.page = 1
      this.getCommentList()
    },
    toggleComplain() {
      this.show = !this.show
    },
    async goComplainSubmit() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await addComplaint(this.form)
      loading.clear()
      const msg = res.resp_msg || '投诉成功'
      this.$toast(msg)
      this.show = false
    },
    async getCommentList() {
      const res = await getCommentList(this.params)
      if (this.refreshing) {
        this.list = []
        this.page = 1
        this.refreshing = false
      }
      const list = res.datas && res.datas.data || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        item.headImgUrl = await getOssUrl(item.headImgUrl)
        this.list.push(item)
      }
      this.loading = false
      const count = res.datas && res.datas.count
      this.count = count
      if (this.list.length >= count * 1) {
        this.finished = true
      }
      this.params.page += 1
      this.firstLoading = false
    },
    async getInfo() {
      const res = await getUserInfo({ type: 1, value: this.saleId })
      const infos = res.datas || {}
      infos.headImgUrl = await getOssUrl(infos.headImgUrl)
      this.infos = infos
      console.log(res)
    }
  }
}
</script>
<style lang="scss" scoped>
.salesman-info {
  .header {
    padding: 10px 23px;
    .box {
      position: relative;
      height: 193px;
      background-image: url(~@/assets/salesman/card@2x.png);
      background-size: 100% 100%;
      .complain {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #666666;
      }
      .head {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 45px;
        .avatar {
          width: 60px;
          height: 60px;
        }
        .des {
          padding-left: 10px;
          .name {
            font-size: 20px;
            color: #2E3135;
          }
          .age {
            display: inline-block;
            padding: 4px 10px;
            margin-top: 10px;
            font-size: 10px;
            border-radius: 11px;
            background: #fff;
            .text {
              display: flex;
              align-items: center;
            }
            img {
              width: 10px;
              margin-right: 5px;
            }
          }
        }
      }
      .cont {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        .item {
          display: flex;
          align-items: center;
          padding: 0 8px;
          img {
            width: 18px;
            margin-right: 5px;
          }
          .tt {
            font-size: 12px;
            color: #666666;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .content {
    .head {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 16px;
      color: #666666;
      border-bottom: 1px solid #F3F3F5;
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
    .list {
      padding: 0 16px;
      .item {
        padding: 15px 0;
        border-bottom: 1px solid #F3F3F5;
        .hd {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
          }
          .ct {
            padding-left: 10px;
            .dt {
              padding-top: 5px;
              font-size: 10px;
              color: #CCCCCC;
            }
          }
        }
        .des {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          font-size: 12px;
          color: #999999;
          .van-rate {
            padding: 0 5px;
          }
        }
        .textarea {
          padding-top: 10px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
      }
    }
    .emprty {
      padding-top: 20px;
      text-align: center;
      img {
        width: 166px;
      }
    }
  }
  .complain-popup {
    width: 270px;
    .head {
      padding-top: 24px;
      font-size: 16px;
      text-align: center;
    }
    .form {
      padding: 16px 10px;
      .van-cell {
        background: #FAFAFA;
      }
    }
    .bottom {
      display: flex;
      border-top:1px solid #E6E6E6 ;
      .btn {
        flex: 1;
        padding: 14px 0;
        text-align: center;
        font-size: 16px;
        color: #666666;
      }
    }
  }
}
</style>
