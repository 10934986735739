<template>
  <div class="look-pdf">
    <div id="demo" />
  </div>
</template>
<script>
import { getOssUrl } from '@/utils/oss'
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
export default {
  data() {
    return {
      pdfh5: ''
    }
  },
  async mounted() {
    const pdfurl = await getOssUrl(this.$route.params.pdf)
    console.log(pdfurl)
    this.pdfh5 = new Pdfh5('#demo', {
      pdfurl,
      cMapUrl: 'https://unpkg.com/pdfjs-dist@3.8.162/cmaps/'
    })
    this.pdfh5.on('complete', function(status, msg, time) {
      console.log('状态：' + status + '，信息：' + msg + '，耗时：' + time + '毫秒，总页数：' + this.totalNum)
      // 禁止手势缩放
      this.pdfh5.zoomEnable(false)
    })
  }
}
</script>
<style lang="scss" scoped>
.look-pdf {
  width: 100%;
  height: 100%;
}
</style>
